<template>
  <div>
    <survey-header
      :survey="survey"
      :category="category"
      :invitation-link="invitationLink"
      :is-display-q-r="true"
    />
    <!-- Questions -->
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <b-row class="mb-1">
              <b-col
                cols="6"
                xl="6"
                md="6"
              >
                <b-input-group class="input-group-merge flex-grow-1">
                  <b-input-group-prepend is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuestionQuery"
                    :placeholder="$t('SEARCH_QUESTIONS')"
                    class="flex-grow-1"
                  />
                </b-input-group>
              </b-col>
              <b-col
                cols="6"
                xl="6"
                md="6"
              >
                <b-form-group label-for="id-categories">
                  <v-select
                    id="id-categories"
                    v-model="selectdCategories"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :placeholder="$t('SELECT_CATEGORY')"
                    class="flex-grow-1"
                    :options="categoryOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="match-height">
              <b-col lg="6">
                <b-card-text>
                  <div style="display: flex; align-items: center;">
                    <span> {{ $t("AVAILABLE_QUESTIONS") }} </span>
                    <b-media-aside
                      vertical-align="center"
                      class="ml-50"
                    >
                      <feather-icon
                        icon="PlusCircleIcon"
                        size="24"
                        class="mr-50 text-primary"
                        @click="$router.push({ name: 'question-list'})"
                      />
                    </b-media-aside>
                  </div>
                </b-card-text>

                <!-- questions -->
                <b-list-group
                  v-for="question in selectdQuestions"
                  :key="question.questionID"
                >
                  <b-list-group-item class="d-flex">
                    <b-form-checkbox
                      :id="question.questionID.toString()"
                      :checked="question.checked || false"
                      :disabled="question.checked"
                      @change="handleQuestionChange(question)"
                    />

                    <div>
                      <div class="d-flex align-items-center">
                        <span
                          class="mr-1"
                          v-html="truncatedHtml(question.statement, 70)"
                        />
                        <b-form-rating
                          v-if="question.question_type===questionType[0]"
                          :id="question.questionID.toString()"
                          inline
                          no-border
                          :stars="question.maximum_value"
                          readonly
                          variant="primary"
                        />

                        <feather-icon
                          v-if="question.question_type===questionType[1]
                            || question.question_type===questionType[2]"
                          :icon="
                            question.question_type === questionType[1] && question.question_variety===questionVariety[0] ? 'FeatherIcon' :
                            question.question_type === questionType[1] && question.question_variety===questionVariety[1] ? 'MailIcon' :
                            'CheckCircleIcon'"
                          size="18"
                          class="text-primary"
                        />
                      </div>
                      <br>

                      <span>
                        <b-badge
                          v-for="(
                            questionCategory, index
                          ) in question.categories"
                          :key="index"
                          :variant="questionCategory.avatar_color"
                          pill
                          class="text-capitalize small mr-0"
                        >
                          {{ questionCategory.name }}
                        </b-badge>
                      </span>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <span
                  class="text-muted mt-1"
                >{{ $t("SHOWING") }} {{ selectdQuestions.length }} of
                  {{ allQuestions.length }} {{ $t("ENTRIES") }}</span>
              </b-col>
              <b-col lg="6">
                <b-card-text>
                  <span> {{ $t("SURVEY_QUESTIONS") }} </span>
                </b-card-text>

                <draggable
                  ref="draggableList"
                  v-model="survey.survey_questions"
                  group="survey"
                  class="list-group"
                  @end="dragEnd"
                >
                  <template>
                    <!-- Survey Questions -->
                    <b-list-group
                      v-for="survey_question in survey.survey_questions"
                      :id="'row_' + survey_question.ID.toString()"
                      :key="survey_question.ID"
                      ref="listItems"
                    >

                      <b-list-group-item
                        class="d-flex"
                      >

                        <b-form-checkbox
                          :id="'chk_mandatory' + survey_question.ID.toString()"
                          :checked="survey_question.is_mandatory || false"
                          class="mr-1"
                          value="1"
                          unchecked-value="0"
                          @change="handleSurveyQuestionChange(survey_question)"
                        >
                          <small>{{ $t('MANDATORY') }}</small>
                        </b-form-checkbox>
                        <div>
                          <div class="d-flex align-items-center">
                            <span
                              class="mr-1"
                              v-html="truncatedHtml(survey_question.question.statement, 70)"
                            />
                            <b-form-rating
                              v-if="survey_question.question.question_type===questionType[0]"
                              :id="survey_question.question.questionID.toString()"
                              inline
                              no-border
                              readonly
                              :stars="survey_question.question.maximum_value"
                              variant="primary"
                            />
                            <feather-icon
                              v-if="survey_question.question.question_type===questionType[1]
                                || survey_question.question.question_type===questionType[2]"
                              :icon="
                                survey_question.question.question_type === questionType[1] && survey_question.question.question_variety===questionVariety[0] ? 'FeatherIcon' :
                                survey_question.question.question_type === questionType[1] && survey_question.question.question_variety===questionVariety[1] ? 'MailIcon' :
                                'CheckCircleIcon'"
                              size="18"
                              class="text-primary"
                            />
                          </div>
                          <br>
                          <span>
                            <b-badge
                              v-for="(questionCategory, index) in survey_question
                                .question.categories"
                              :key="index"
                              :variant="questionCategory.avatar_color"
                              pill
                              class="text-capitalize small mr-0"
                            >
                              {{ questionCategory.name }}
                            </b-badge>
                          </span>
                          <feather-icon
                            :id="survey_question.ID"
                            icon="EditIcon"
                            size="16"
                            class="ml-1"
                            @click="$router.push({ name: 'question-configuration-id', params: { id: survey_question.question.questionID }})"
                          />
                          <feather-icon
                            :id="survey_question.ID"
                            icon="Trash2Icon"
                            size="16"
                            class="ml-1"
                            @click="handleDeleteQuestion(survey_question)"
                          />
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </template>
                </draggable>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- Users -->
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <b-row class="match-height">
              <b-col lg="12">
                <b-card-text class="mb-1">
                  <div style="display: flex; align-items: center;">
                    <span> {{ $t("SURVEY_PARTICIPANTS") }} </span>
                    <b-media-aside
                      vertical-align="center"
                      class="ml-50"
                    >
                      <feather-icon
                        icon="PlusCircleIcon"
                        size="24"
                        class="text-primary"
                        @click="$router.push({ name: 'project-view-id', params: { id: survey.project_id }})"
                      />
                    </b-media-aside>
                  </div>
                </b-card-text>
              </b-col>
            </b-row>
            <p><span class="text-red">*** </span>{{ $t("MESSAGE.COLOR_INDICATION") }}</p>
            <b-table
              :items="survey.survey_users"
              responsive
              :fields="tableColumns"
              :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
              primary-key="id"
              class="mb-0"
            >

              <!-- Name -->
              <template #cell(Name)="data">
                <div class="d-flex align-items-center">
                  <span class="font-weight-bolder mr-1">{{ data.item.user.firstname }} {{ data.item.user.lastname }}</span>
                </div>
                <small class="text-muted">{{ data.item.user.email }}</small>
              </template>
              <!-- Business unit -->
              <template #cell(BusinessUnit)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      <span>{{ data.item.user.business_unit }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Last Login -->
              <template #cell(LastLogin)="data">
                <div class="d-flex align-items-left text-nowrap">
                  <div>
                    <small class="text-muted">{{ data.item.user.lastlogin ? formatDateTime(data.item.user.lastlogin) : $t('NEVER_LOGGED') }}</small>
                  </div>
                </div>
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`project-row-${data.item.user_id}-preview-icon`"
                    icon="Link2Icon"
                    size="16"
                    class="cursor-pointer mx-1"
                    @click="navigateSurveyPage(data.item.user)"
                  />
                  <feather-icon
                    :id="`project-row-${data.item.user_id}-preview-icon`"
                    icon="MessageCircleIcon"
                    size="16"
                    :class="data.item.is_sms > 0 ? 'cursor-pointer mr-1 text-success' : 'cursor-pointer mr-1 text-warning'"
                    @click="sendSmsInvitationToUser(data.item)"
                  />
                  <feather-icon
                    :id="`project-row-${data.item.user_id}-preview-icon`"
                    icon="MailIcon"
                    size="16"
                    :class="data.item.is_email > 0 ? 'cursor-pointer mr-1 text-success' : 'cursor-pointer mr-1 text-warning'"
                    @click="sendEmailInvitationToUser(data.item)"
                  />
                  <feather-icon
                    :id="`game-row-${data.item.user_id}-delete-icon`"
                    icon="Trash2Icon"
                    size="16"
                    class="cursor-pointer mr-1"
                    @click="handleDeleteUser(data.item)"
                  />
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BInputGroupPrepend,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BFormRating,
  BBadge,
  BListGroup,
  BListGroupItem,
  BCardText,
  BMediaAside,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import draggable from 'vuedraggable'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'
import SurveyHeader from './SurveyHeader.vue'
import { mixinDate } from '@/constants/mixinDate'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BBadge,
    BListGroup,
    BListGroupItem,
    BCardText,
    vSelect,
    BFormRating,
    draggable,
    BMediaAside,
    SurveyHeader,
    BTable,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      survey: {
        ID: 0,
        copy_id: 0,
        name: '',
        start_date: '',
        end_date: '',
        total_users: 0,
        total_responses: 0,
        average_rating: 0,
        survey_status: '',
        frequency: '',
        sequence: '',
        project_id: 0,
        company_id: 0,
        category_id: 0,
        description: constants.SURVEY_DESCRIPTION,
        survey_questions: [],
      },
      maxQuestionId: 0,
      maxUserId: 0,
      category: {},
      searchQuestionQuery: '',
      searchUserQuery: '',
      categoryOptions: [],
      selectdQuestions: [],
      allQuestions: [],
      selectdCategories: [],
      allSurveys: [],
      currentPage: 1,
      invitationLink: '',
      questionType: constants.QUESTION_TYPE,
      questionVariety: constants.QUESTION_VARIETY,
      encryptedDefaultId: constants.ENCRYPTED_DEFAULT_ID,
      tableColumns: [
        { key: 'Name' },
        { key: 'BusinessUnit' },
        { key: 'LastLogin' },
        { key: 'Actions' },
      ],
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      avatarText,
    }
  },
  watch: {
    searchQuestionQuery: 'filterQuestionsByConditions',
    selectdCategories: 'filterQuestionsByConditions',
    searchUserQuery: 'filterUsersByConditions',
  },
  async created() {
    await this.fetchQuestions()
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.survey.ID = parseInt(router.currentRoute.params.id)
      if (this.survey.ID !== 0) {
        this.fetchSurveyByID(this.survey.ID).then(async response => {
          if (response) {
            this.survey = response.data
            this.maxQuestionId = this.getMaxQuestionId(this.survey)
            await this.fetchCategory()
            await this.fetchUsers(this.survey.project_id)
            this.updateAllQuestionsWithCheckedProperty()
            this.selectdQuestions = [...this.allQuestions.slice(0, 10)]
            this.setInvitationLink(this.survey)
          }
        })
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('template', ['getAllCategories']),
    ...mapGetters('survey', ['getAllQuestions']),
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories']),
    ...mapActions('project', ['fetchProjectByID']),
    ...mapActions('survey', [
      'createSurveyQuestion',
      'updateSurveyQuestion',
      'deleteSurveyQuestion',
      'deleteSurveyUser',
      'fetchSurveyByID',
      'sendSurveyInvitation',
      'fetchAllQuestions',
    ]),
    getMaxQuestionId(data) {
      const surveyQuestions = data.survey_questions

      // Check if the array is not empty
      if (surveyQuestions && surveyQuestions.length > 0) {
        // Extract an array of question IDs
        const questionIds = surveyQuestions.map(question => question.ID)

        // Find the maximum ID
        return Math.max(...questionIds)
      }
      // If survey_questions is empty, return a default value (e.g., 1)
      return 0
    },
    reloadData() {
      this.fetchSurveyByID(this.survey.ID).then(async response => {
        if (response) {
          this.survey = response.data
        }
      })
    },
    navigateSurveyPage(data) {
      const link = `${process.env.VUE_APP_HOST}bisevo/survey/${this.survey.encryptID}?userKey=${data.encryptUserID}`
      window.open(link, '_blank')
    },
    setInvitationLink(data) {
      this.invitationLink = `${process.env.VUE_APP_HOST}bisevo/survey/${data.encryptID}?userKey=${this.encryptedDefaultId}`
      if (data.survey_status === constants.SURVEY_STATUS[1]) {
        this.invitationLink = `${process.env.VUE_APP_HOST}bisevo/survey-results/${data.encryptID}`
      }
    },
    sendEmailInvitationToUser(data) {
      if ((data.user.email)) {
        const message = data.is_email === 0
          ? this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')
          : this.$i18n.t('MESSAGE.RE_INVITATION_EMAIL_CONFIRMATION')
        this.$bvModal
          .msgBoxConfirm(`${message} ${this.survey.name}   ${this.$i18n.t('Survey')}  to ${data.user.firstname}  ${data.user.lastname} ?`, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // eslint-disable-next-line no-param-reassign
              data.is_email = 1
              const payload = {
                id: this.survey.ID,
                user_id: data.user_id,
                message_type: 'email',
              }
              this.sendSurveyInvitation(payload).then(response => {
                if (response) {
                  this.successMessage(response.data.message)
                }
              }).catch(() => {
                this.showErrorMessage()
              })
            }
          })
      } else {
        this.errorMessage(this.$i18n.t('MESSAGE.EMAIL_UNAVAILABLE'))
      }
    },
    sendSmsInvitationToUser(data) {
      if ((data.user.country_code) && (data.user.phonenumber)) {
        this.$bvModal
          .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_SMS_CONFIRMATION')} ${this.survey.name}   ${this.$i18n.t('Survey')}  to ${data.user.firstname}  ${data.user.lastname} ?`, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // eslint-disable-next-line no-param-reassign
              data.is_sms = 1
              const payload = {
                id: this.survey.ID,
                user_id: data.user_id,
                message_type: 'sms',
              }
              this.sendSurveyInvitation(payload).then(response => {
                if (response) {
                  this.successMessage(response.data.message)
                }
              }).catch(() => {
                this.showErrorMessage()
              })
            }
          })
      } else {
        this.errorMessage(this.$i18n.t('MESSAGE.PHONE_NUMBER_UNAVAILABLE'))
      }
    },
    async dragEnd() {
      try {
        this.survey.survey_questions.forEach(async (surveyQuestion, index) => {
          // eslint-disable-next-line no-param-reassign
          surveyQuestion.sequence = index + 1
          await this.updateSurveyQuestion(surveyQuestion)
        })
        this.successMessage(this.$i18n.t('MESSAGE.QUESTION_SEQUENCE_UPDATED'))
      } catch (error) {
        this.showErrorMessage()
      }
    },
    async updateAllQuestionsWithCheckedProperty() {
      const questionIdsInSurvey = this.survey.survey_questions.map(
        surveyQuestion => surveyQuestion.question.questionID,
      )
      this.allQuestions = this.allQuestions.map(question => ({
        ...question,
        checked: questionIdsInSurvey.includes(question.questionID),
      }))
      this.selectdQuestions = this.selectdQuestions.map(question => ({
        ...question,
        checked: questionIdsInSurvey.includes(question.questionID),
      }))
    },
    filterUsersByConditions() {
      const queryLowered = this.searchUserQuery.toLowerCase()
      // Check conditions
      let filteredUsers
      if (this.searchUserQuery !== '') {
        filteredUsers = this.allUsers.filter(user => user.firstname.toLowerCase().includes(queryLowered)
      || user.lastname.toLowerCase().includes(queryLowered))
      } else {
        filteredUsers = [...this.allUsers.slice(0, 10)]
      }
      this.selectdUsers = filteredUsers
    },
    filterQuestionsByConditions() {
      const queryLowered = this.searchQuestionQuery.toLowerCase()
      const categoryIds = this.selectdCategories.map(category => category.ID)
      // Check conditions
      let filteredQuestions
      if (this.searchQuestionQuery !== '' && categoryIds.length > 0) {
        filteredQuestions = this.allQuestions.filter(question => {
          const hasCategories = question.categories && question.categories.length > 0
          const matchesStatement = question.statement
            .toLowerCase()
            .includes(queryLowered)
          return (
            hasCategories
            && matchesStatement
            && question.categories.some(category => categoryIds.includes(category.ID))
          )
        })
      } else if (this.searchQuestionQuery !== '') {
        filteredQuestions = this.allQuestions.filter(question => question.statement.toLowerCase().includes(queryLowered))
      } else if (categoryIds.length > 0) {
        filteredQuestions = this.allQuestions.filter(question => {
          const hasCategories = question.categories && question.categories.length > 0
          return (
            hasCategories
            && question.categories.some(category => categoryIds.includes(category.ID))
          )
        })
      } else {
        filteredQuestions = [...this.allQuestions.slice(0, 10)]
      }

      this.selectdQuestions = filteredQuestions
    },
    async fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
          if (this.survey.ID) {
            this.category = this.categoryOptions.find(
              i => i.ID === this.survey.category_id,
            )
          }
        }
      })
    },
    handleQuestionChange(question) {
      // eslint-disable-next-line no-param-reassign
      question.checked = !question.checked
      if (question.checked) {
        this.maxQuestionId += 1
        // Checkbox is checked, perform your action
        const objectSurvey = {
          ID: this.maxQuestionId,
          question_id: question.questionID,
          is_new: true,
          survey_id: this.survey.ID,
          question,
        }
        this.createSurveyQuestion({
          question_id: question.questionID,
          sequence: this.survey.survey_questions.length + 1,
          survey_id: this.survey.ID,
        })
        this.survey.survey_questions.push(objectSurvey)
        this.updateAllQuestionsWithCheckedProperty()
      }
    },
    handleSurveyQuestionChange(surveyQuestion) {
      // eslint-disable-next-line no-param-reassign
      const data = { ...surveyQuestion }
      data.is_mandatory = !data.is_mandatory
      this.updateSurveyQuestion(data)
        .then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.QUESTION_UPDATED'))
          }
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    handleDeleteQuestion(questionToRemove) {
      // eslint-disable-next-line no-param-reassign
      const indexToRemove = this.survey.survey_questions.findIndex(
        question => question.ID === questionToRemove.ID,
      )
      if (indexToRemove !== -1) {
        this.survey.survey_questions.splice(indexToRemove, 1)
        this.updateAllQuestionsWithCheckedProperty()
        this.deleteSurveyQuestion(questionToRemove)
      }
    },
    handleDeleteUser(userToRemove) {
      // eslint-disable-next-line no-param-reassign
      const indexToRemove = this.survey.survey_users.findIndex(
        user => user.ID === userToRemove.ID,
      )
      if (indexToRemove !== -1) {
        this.survey.survey_users.splice(indexToRemove, 1)
        this.deleteSurveyUser(userToRemove)
      }
    },
    async fetchQuestions() {
      const currentPage = 1
      const params = `/${currentPage}?survey_id=0`
      try {
        await this.fetchAllQuestions(params).then(() => {
          this.allQuestions = [...this.getAllQuestions]
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    async fetchUsers(projectId) {
      await this.fetchProjectByID(projectId).then(response => {
        if (response.data) {
          this.allUsers = response.data.project_users.map(item => item.user)
          this.selectdUsers = [...this.allUsers.slice(0, 10)]
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.text-red {
  color: red;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
